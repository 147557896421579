import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockRichText from "../components/common/subblock/subblock-rich-text";
import * as style from "../styles/impressum.module.scss"
import * as headerStyle from "../styles/header.module.scss";

const Datenschutzerklaerung = ({ data }) => {
  // const header = data.strapiDatenschutzerklaerung.header
  const datenschutzText = data.strapiDatenschutzerklaerung.datenschutzText

  return (
    <>
      <Seo title="Datenschutzerklärung" />
      <Layout>
        {/* Header Gap needed because no pages header with Picture is used */}
        <div className={headerStyle.headerGap} />
        <SubblockRichText content={datenschutzText} position="center" fullwidth className={style.impressum}/>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
query strapiDatenschutzerklaerungQuery {
  strapiDatenschutzerklaerung {
    header {
      ...fragmentHeader
    }
    datenschutzText {
      __typename
      data {
        id
        childMarkdownRemark {
          html
        }
      }
    }
  }
}
`

export default Datenschutzerklaerung
